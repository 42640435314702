import React from 'react'

const Loading = (props) => {
    return (
        <>  
            <div className={"section-loading " + (props.absolute ? 'absolute' : '') }>
                <div class="loader"></div>
            </div>
        </>
    )
}

export default Loading
